@font-face {
  font-family: gotham;
  src: url(./assets/fonts/Gotham-Medium.otf);
}

body {
  font-family: "gotham", sans-serif;
}

.main-header {
  position: relative;
  height: 120vh;
  background-image: url('./assets/images/banner.jpg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-header::after {
  content: '';
  position: absolute;
  top: -50px;
  left: -100px;
  background-image: url('./assets/images/slider-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 45%;
  height: 120%;
  overflow: hidden;
  opacity: 0.9;
  /* mix-blend-mode: multiply; */
  transform: rotate(-15deg);
}

.main-header-science {
  position: relative;
  height: 120vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
  url('./assets/images/scie.jpg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-header-science2 {
  position: relative;
  height: 120vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
  url('./assets/images/blog-2.webp');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.main-header-science3 {
  position: relative;
  height: 120vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
  url('./assets/images/blog3.jpg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

nav {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 3;
}

.navbar-brand img {
  width: 100px;
}

.navbar-nav {
  padding-left: 200px !important;
}

.nav-links {
  font-weight: 700 !important;
  color: #fff !important;
  font-size: 18px;
  margin: 0 20px !important;
}


.main-title {
  position: absolute;
  bottom: 50px;
  color: #FFF;
  z-index: 2;
}

.main-title h1 {
  font-weight: 600;
  font-size: 55px;
  padding: 0;
  margin: 0;
}

.main-title span {
  font-size: 30px;
  padding: 0;
  margin: 0;
}

/*-------------------map section--------  */

.map-section {
  height: 100vh;
  width: 100%;
  background-image: url(./assets/images/tn.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-color: #fff;
}

.mob-img {
  display: none;
}

.mob-img img {
  width: 100%;
  height: 100vh;
}

.box-map {
  background-color: #fff;
  width: 100%;
  color: #000;
  height: 400px;
  margin-top: 100px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.f-box img {
  width: 200px;
  /* height: 40px; */
}

/* ============banner-app======== */
.banner-app {
  background-image: url(./assets/images/child-gren.png);
  background-color: #68BD45;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 200px;
  width: 100%;
  background-blend-mode: multiply;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner-app h3 {
  line-height: 75px;
  font-weight: 700;
  text-transform: capitalize !important;
  color: #fff;
  font-size: 40px;
}

.apps img {
  width: 130px;
  margin: 0 20px;
}


.main-part {
  display: flex;
  align-items: center;
}

.img-sec {
  width: 50%;
  background-image: url(./assets/images/sec-c.png);
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-sec {
  width: 50%;
  padding: 50px 100px 20px 40px;
  position: relative;
}

.content-sec h2 {
  color: #68BD45;
  font-size: 50px;
}

.vec {
  position: absolute;
  top: 80px;
  right: 0;
}

.vec img {
  width: 30px;
  height: 30px;
  animation: newanim 3s ease-in-out infinite;

}

.content-sli h2 {
  color: #68BD45;
  font-size: 60px;
  font-weight: 700;
  padding-bottom: 20px;
}

.slider-bg {
  width: 100%;
  background-image: url(./assets/images/slider-bg.png);
  background-position: left;
  background-size: 60% 100%;
  background-repeat: no-repeat;
}

.slider-content {
  color: #fff;
  padding: 20px;
}

.list-of-item {
  text-align: left;
}

.imgsd {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.slider-content h2 {
  font-size: 60px;
  padding: 40px 0;
  text-align: left;
}

@keyframes newanim {
  0% {
    width: 30px;
  }

  100% {
    width: 100px;
  }
}

.slider-banner {
  background-image: url(./assets/images/slider-banner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 50px 0;
  /* height: 100vh; */
}

.slider-title {
  color: #68BD45;
  font-size: 50px;
  font-weight: 600;
  padding: 40px 0;
}

.new-car {
  background-color: #FFF;
  border-radius: 10px;
  /* height: 200px; */
  margin: 0 20px;
  padding: 10px;
  height: 300px;
}

.arrow-button button {
  border: 3px solid #413f3f;
  border-radius: 50%;
  background: none;
  outline: none;
  margin: 10px;
}

.new-car h5 {
  color: #68BD45;
  text-align: left;
}

.new-car p {
  text-align: left;
  font-size: 12px;
}

.card-bpdy {
  background-color: #68BD45 !important;
}


.shape {
  background-image: url(./assets/images/shpe.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
  width: 100%;
  /* padding: 50px 0; */
}

.banner-secs {
  background-image: url(./assets/images/banners.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;

}

.main-list {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

}

.main-list h2 {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  padding: 40px 0;
}

.main-list button {
  border: 3px solid #fff;
  border-radius: 10px;
  background-color: #73AA5D;
  padding: 10px 30px;
  font-size: 30px;
  color: #fff;
}

.buy-btn,
.buy-btns {
  border: 3px solid #fff !important;
  border-radius: 10px;
  background-color: #73AA5D !important;
  padding: 5px 10px;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  width: fit-content;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.a-text {
  text-decoration: none;
}


.third-banner {
  background-image: url(./assets/images/third-banner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-box {
  background-color: rgb(0, 0, 0, 0.5);
  height: 400px;
  border-radius: 20px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  color: #fff;
}

.detial {
  display: flex;
  align-items: center;
}

.lab {
  font-size: 20px;
  font-weight: 600;
  padding-right: 20px;
  width: 20%;
}

.bg-1 h2 {
  color: #68BD45;
  font-size: 40px;
  font-weight: 700;
}

.banner-app1 {
  background-image: url(./assets/images/child-gren.png);
  background-color: #68BD45;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* height: 200px; */
  width: 100%;
  background-blend-mode: multiply;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 0 20px 0;
}

.footer-logo {
  width: 200px;
}

.social-logo {
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  padding: 0 30px;
  text-align: center;
}

.social-logo a {
  margin: 0 10px;
}

.app-link a {
  text-align: center;
}

.app-link a img {
  width: 100px;
  margin: 0 10px;
}

.list-navs {
  list-style: none;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  text-align: center;
  justify-content: space-evenly;
}

.list-navs li {
  border-right: 2px solid #fff;
  padding: 0 10px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

.list-navs li a {
  text-decoration: none;
  color: #fff;
}

.list-navs li:nth-last-child() {
  border-right: none;
}

.footer-bottom {
  font-size: 35px;
  text-align: center;
  font-weight: 700;
  color: #fff;
}

/* ============responsive========= */

@media screen and (max-width:768px) {
  .main-header {
    position: relative;
    height: 50vh;
    background-image: url('./assets/images/mobile-bg.png');
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .main-header::after {
    content: '';
    position: absolute;
    top: -20px;
    left: -100px;
    background-image: url('./assets/images/slider-bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 60%;
    height: 120%;
    overflow: hidden;
    opacity: 0.8;
    transform: rotate(-15deg);
  }

  .main-title h1 {
    font-weight: 600;
    font-size: 35px;
    padding: 0;
    margin: 0;
  }

  .main-title span {
    font-size: 15px;
    padding: 0;
    margin: 0;
  }

  .mob-img {
    display: block;
  }

  .navbar-brand img {
    width: 60px;
  }

  .navbar-nav {
    background-color: rgb(0, 0, 0, 0.5);
    width: 100%;
    margin: 0 auto;
  }

  .top-map {
    position: relative;
  }

  .top-1 {
    position: relative;
    height: 50vh;
    background-image: url(./assets/images/tn-map.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

  }

  .map-section {
    /* display: none; */
    height: auto;
    background-image: none;
  }

  .banner-app h3 {
    font-size: 20px;
  }

  .img-sec {
    width: 50%;
    background-image: url(./assets/images/sec-c.png);
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content-sec {
    width: 50%;
    padding: 10px;
    position: relative;
  }

  .content-sec h2 {
    font-size: 20px;
  }

  .content-sec p {
    font-size: 12px;
  }

  .iner-sec {
    display: none;
  }

  @keyframes newanim {
    0% {
      width: 10px;
    }

    100% {
      width: 50px;
    }
  }

  .content-sli h2 {
    font-size: 30px;
    padding: 20px 0;
  }

  .slider-bg {
    background-size: cover;
  }

  .slider-content h2 {
    font-size: 30px;
  }

  .slider-title {
    font-size: 30px;
  }

  .main-list h2 {
    font-size: 30px;
  }

  .footer-logo {
    width: 80px;
  }

  .social-logo {
    display: inline-block;
    padding: 0 30px;
  }

  .list-navs li {
    font-size: 14px;
  }

  .footer-bottom {
    font-size: 14px;
  }

}


/*============== */

.testi-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.testi-con img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.blog-con h3{
font-size: 40px;
padding: 40px 0;
}